import React from "react";
import { PageLink } from "./link";
import ctaStyles from "./cta.module.css";

export const Pagination = ({
  next,
  prev,
}: {
  next?: string;
  prev?: string;
}) => (
  <div className="flex justify-between  max-w-6xl w-full mx-auto mt-10 px-4">
    {prev && (
      <PageLink to={prev} className={ctaStyles.link}>
        Newer
      </PageLink>
    )}
    {next && (
      <PageLink to={next} className={`${ctaStyles.link} ml-auto`}>
        Older
      </PageLink>
    )}
  </div>
);
