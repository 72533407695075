import React from "react";

import { RenderModules } from "../utils/renderModules";
import { SEO } from "../components/seo";
import { Pagination } from "../components/pagination";

export interface BlogPostProps {
  pageContext: {
    main: {
      modules: [];
      date: string;
      author: string;
      featuredImg: any;
      slug: {
        current: string;
      };
      title: string;
    };
    meta: {};
    next?: string;
    prev?: string;
  };
  path?: string;
  preview?: boolean;
}

const BlogPost = ({ path, pageContext, preview = false }: BlogPostProps) => {
  const {
    main: { modules, slug, date, author, featuredImg, title },
    meta,
    next,
    prev,
  } = pageContext;

  return (
    <div className="mt1 x ac">
      {preview && <div className="bcblue ac cw x p1">This is a Preview</div>}
      <SEO metaInfo={meta} pagePath={path} />
      <div className="max-w-4xl mx-auto text-center px-4 w-full pt-10 pb-6">
        <h1 className="text-ocean mb-6">{title}</h1>
        {(author || date) && (
          <p className="text-ocean">
            {author && <span className="pr-4">{author}</span>}{" "}
            {date && (
              <span>
                {new Date(date).toLocaleDateString("en-US", {
                  year: "numeric",
                  month: "short",
                  day: "numeric",
                })}
              </span>
            )}
          </p>
        )}
      </div>
      <div className="container--m mxa x al">{RenderModules(modules)}</div>
      <Pagination next={next} prev={prev} />
    </div>
  );
};

export default BlogPost;
